<template>
    <div class="rgpd-page step popup column row_center" v-show="visibleRgpd">
        <header class="header">
            <h1 class="title title1">
                Règlement Général sur la Protection des 
                <span class="secondaryColor">Données personnelles</span>
            </h1>
            <div class="separation--short"></div>
            <h2 class="title2">Nous n’enregistrons pas votre adresse IP ni n’utilisons de cookies.</h2>
        </header>
         <main class="main">
            <p class="text text-center">En acceptant notre Réglement Général, vous nous autorisez à enregistrer uniquement les données que vous renseignerez dans le formulaire afin de vous fournir une estimation personnalisée et de vous recontacter si vous le demandez</p>
            <p class="text text-center">Les données collectées ne seront communiquées qu'à Blanc Pur Peinture et ne seront ni vendues ni cédées à un tiers.</p>
            <p class="text text-center">Conformément à la loi «Informatique et Libertés», vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données en écrivant à <a href="mailto:blancpurpeinture@gmail.com">blancpurpeinture@gmail.com</a></p>
        </main>
        <div class="pagination row center">
            <div class="btn primaryBgColor flex5" @click="visibleRgpd = !visibleRgpd">
            Retour
            </div>
        </div>
    </div> 
</template>

<script>
export default {
  name: 'RgpdComponent',
  data(){
      return{
          visibleRgpd : true,
      }
  }
}
</script>