<template>
  <header class="header column row_center">
    <img class="logo--little" src="../assets/logo.svg" alt="Logo de Blanc Pur Peinture, les artisans spécialistes de la peinture au spray">
    <h1 class="title title2"> {{ title }} </h1>
  </header> 
</template>

<script>
export default {
  name: 'CommonHeader',
  props: {
    title: String
  }
}
</script>