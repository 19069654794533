<template>
  <div class="page column">
   <div class="step column flex1">
        <header class="header column row_center center">
            <img class="logo--large" src="../assets/logo.svg" alt="Logo de Blanc Pur Peinture, les artisans spécialistes de la peinture au spray">
        </header>
        <main class="main column row_center flex5 evenly">
            <p class="text text-center">Votre demande de rappel est bien prise en compte !</p>     
            <p class="text text-center">Un artisan de Blanc Pur Peinture vous rappelera dans les 24h</p>
            <p class="text-info">Si le numéro de téléphone que vous avez renseigné lors de l'estimation n'est pas le bon, merci de nous contacter par mail.</p>
        </main> 
        <footer class="row row-center center">
            <contact-component contactText="" href1="tel:+33768892129" href2="mailto:bonjour@blancpurpeinture.fr" icon1="phone2" icon2="arroba" />
            <contact-component contactText="" href1="https://www.facebook.com/Blanc-Pur-Peinture-102357285587519" href2="https://instagram.com/blancpurpeinture?utm_medium=copy_link" icon1="facebook" icon2="instagram" />
        </footer>
    </div>
  </div>
</template>  

<script>
import ContactComponent from '@/components/ContactComponent.vue'

export default {
  name: 'CallbackRequest',
  components: {
    ContactComponent
  }
}

</script>
