<template>
  <div class="btn--contact">
    <p class="text-contact uppercase text-center">{{ contactText }}</p>
    <div class="row row_center center">
      <a :href="href1" target="_blank" class="btn--rounded--icon"><span :class="'icon icon-' + icon1"></span></a>
      <a :href="href2" target="_blank" class="btn--rounded--icon"><span :class="'icon icon-'+ icon2"></span></a>
    </div> 
  </div>
</template>

<script>
export default {
  name: 'ContactComponent',
  props: {
    contactText: String,
    href1: String,
    href2: String,
    icon1: String,
    icon2: String
  }
}
</script>