<template>
  <div class="relative" id="app--content">
      <div class="bg-desktop">
        <img class="bg-silhouette" src="./assets/silhouette-BPP.svg"/>
        <div class="bgwhite"></div>
      </div>
     <router-view/>
  </div>
  
</template>

<style lang="scss"> 
    @import "scss/_fonts.scss";
    @import "scss/_normalize.scss";
    @import "scss/_common-styles.scss";
    @import "scss/_pages.scss";
    @import "scss/_mediaqueries.scss";
 </style> 