import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CallbackRequest from '../views/CallbackRequest.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {title: 'BBP - Votre Estimation'}
  },
  { path: '/estimation', redirect: '/' },
  {
    path: '/CallbackRequest',
    name: 'CallbackRequest',
    component: CallbackRequest,
    meta: {title: 'BBP - Votre Demande de rappel'}
  },
  {
    path: '/InfosClient',
    name: 'ClientInfos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router



