<template>
  <div class="loading-spinner--container">
    <div class="loadingio-spinner-double-ring-5f645zzg0vv">
        <div class="ldio-kmkltiy1xt">
        <div></div>
        <div></div>
        <div><div></div></div>
        <div><div></div></div>
        </div></div>
  </div> 
</template>

<script>
export default {
  name: 'LoadingSpinner',
}
</script>


<style type="text/css">
@keyframes ldio-kmkltiy1xt {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.loading-spinner--container{
  text-align: center;
  flex: 1;
}
.ldio-kmkltiy1xt div { box-sizing: border-box!important }
.ldio-kmkltiy1xt > div {
  position: absolute;
  width: 140px;
  height: 140px;
  top: 30px;
  left: 30px;
  border-radius: 50%;
  border: 12px solid #000;
  border-color: #253d66 transparent #253d66 transparent;
  animation: ldio-kmkltiy1xt 3.3333333333333335s linear infinite;
}

.ldio-kmkltiy1xt > div:nth-child(2), .ldio-kmkltiy1xt > div:nth-child(4) {
  width: 112px;
  height: 112px;
  top: 44px;
  left: 44px;
  animation: ldio-kmkltiy1xt 3.3333333333333335s linear infinite reverse;
}
.ldio-kmkltiy1xt > div:nth-child(2) {
  border-color: transparent #cc0b10 transparent #cc0b10
}
.ldio-kmkltiy1xt > div:nth-child(3) { border-color: transparent }
.ldio-kmkltiy1xt > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-kmkltiy1xt > div:nth-child(3) div:before, .ldio-kmkltiy1xt > div:nth-child(3) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: -12px;
  left: 52px;
  background: #253d66;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #253d66;
}
.ldio-kmkltiy1xt > div:nth-child(3) div:after {
  left: -12px;
  top: 52px;
  box-shadow: 128px 0 0 0 #253d66;
}

.ldio-kmkltiy1xt > div:nth-child(4) { border-color: transparent; }
.ldio-kmkltiy1xt > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-kmkltiy1xt > div:nth-child(4) div:before, .ldio-kmkltiy1xt > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: -12px;
  left: 38px;
  background: #cc0b10;
  border-radius: 50%;
  box-shadow: 0 100px 0 0 #cc0b10;
}
.ldio-kmkltiy1xt > div:nth-child(4) div:after {
  left: -12px;
  top: 38px;
  box-shadow: 100px 0 0 0 #cc0b10;
}
.loadingio-spinner-double-ring-5f645zzg0vv {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-kmkltiy1xt {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-kmkltiy1xt div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>