<template>
  <div class="page column">
    <section v-if="currentStep == 0" class="step step0 flex1 column">
      <header class="header  column row_center center">
        <img class="logo--large" src="../assets/logo.svg" alt="Logo de Blanc Pur Peinture, les artisans spécialistes de la peinture au spray">
        <h1 class="title title1"><span class="secondaryColor">Bienvenue</span> chez Blanc pur peinture ! </h1>
        <div class="separation--short"></div>
      </header> 
      <main class="main column row_center flex1 evenly">
        <p class="text text-center">Votre <strong>Spraycialiste d'application de peinture</strong> pour <strong>maisons neuves</strong> et <strong>rénovations</strong> sur <strong>Avignon</strong> et sa <strong>grande région</strong>.</p>
        <p class="text text-center">Blanc Pur Peinture est une entreprise de peinture en bâtiment et de pose de revêtements de sols en tout genre.</p>
        <p class="text text-center">Nous nous adressons aux particuliers comme aux professionnels, pour des projets des plus simples jusqu’aux plus audacieux.</p>
        <p class="text text-center">Afin de vous garantir un <strong>résultat de grande qualité</strong> et parce que les plannings sont bien souvent serrés, nous avons fait le choix de devenir des <strong>Spraycialistes</strong>, en utilisant la <strong>technologie Airless</strong> pour l’application de nos peintures et enduits. Pour les plus traditionnels, et si la situation nous l’impose, nous sortirons nos plus beaux rouleaux et pinceaux …</p>
        <p class="text text-center">Grâce à Blanc Pur Peinture, <strong>obtenez immédiatement une estimation gratuite</strong> et <strong>sans engagements</strong> de la valeur des travaux de peinture pour votre logement.</p>
        <p class="text text-center">Nous n’oublions pas que votre <strong>projet est unique</strong>. Aussi nous restons <strong>à votre écoute et disposition</strong> pour faire en sorte que vos idées puissent se réaliser …</p>
        <div class="centered_absolute-element need-mTB row row_center">
            <input type="checkbox" id="rgpd" class="checkbox" :checked="rgpdAccepted">
            <label class="btn--onoff btn-rgpd" for="rgpd" @click.prevent="rgpdAccepted = !rgpdAccepted"></label>
            <p class="text--rgpd">J’accepte les <span class="underline"  @click="showRgpd()">conditions d’utilisation (RGPD)</span></p>
        </div>
        <p class="error--msg">{{formErrorMsg}}</p>
      </main>
      <div class="pagination row center">
        <button 
            class="btn secondaryBgColor flex5" 
            @click="checkRgpd()">
            Démarrer mon estimation
        </button>
      </div>
    </section>

    <rgpd-component v-if="visibleRgpd"/>


    <section v-if="currentStep == 1" class="step step1 flex1 column"> 
      <CommonHeader title="Faisons connaissance" /> 
      <main class="main column flex1">
        <div class="form flex1 column evenly">
          <div class="form-item">
            <label class="label" for="name">Nom</label>
            <input v-model="customerData.name" type="text" name="name" id="name" maxlength="15" placeholder="Écrire ici ..." required>
          </div>
          <div class="form-item">
            <label class="label" for="tel">Téléphone</label>
            <input v-model="customerData.cellphone" type="tel" name="tel" id="tel" pattern="[0-9]{10}" placeholder="Écrire ici ..." required>
          </div>
          <div class="form-item">
            <label class="label" for="name">Adresse Mail</label>
            <input v-model="customerData.email" type="mail" name="mail" id="mail" placeholder="Écrire ici ..." required>
          </div>
          <p class="text-info">Vos données nous sont exclusivement réservées.<br/>Nous ne nous en séparerons jamais.</p>
        </div>
        <p class="error--msg">{{formErrorMsg}}</p>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor"  @click="goPrevStep()">
          Retour
        </button>
        <button
          class="btn secondaryBgColor" 
          @click="checkIdentityForm()">
          Continuer
        </button>
      </div>
    </section>


    <section v-if="currentStep == 2" class="step step2 flex1 column evenly">
      <CommonHeader title="Nous intervenons à peu près partout … Vous êtes d’où ?" />
      <main class="main column flex1">
        <div class="form flex1 column">
          <div class="form-item">
            <label class="label" for="address">Lieu du chantier</label>
            <input v-model="customerData.address" type="text" name="address" id="address" maxlength="20" placeholder="Écrire ici la ville" required>
          </div>
          <div class="form-item">
            <p class="label">Délai d'intervention souhaité</p> 
           <div class="custom-select" :data-value="customerData.date" :data-list="list">
              <div class="selector relative" @click="toggle()">
                  <div class="input row row_center between">
                    <span :class="{default : !customerData.date }">{{ customerData.date || defaultValue}} </span>
                    <span class="icon icon-arrow" :class="{ expanded : visibleOptions }"></span>
                  </div>
                    <div :class="['choices-list', { hidden : !visibleOptions, visibleOptions}]">
                        <ul>
                            <li :class="{ current : item === customerData.date }" v-for="item in list" @click="select(item)" :key="item">{{ item }}</li>
                        </ul>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <p class="error--msg">{{formErrorMsg}}</p>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor"  @click="goPrevStep()">
          Retour
        </button>
        <button
          class="btn secondaryBgColor" 
          @click="checkSiteForm()">
          Continuer
        </button>
      </div>
    </section>

    <!-- ancienne currentStep 5 -->
    <section v-if="currentStep == 3" class="step step5 column flex1">
      <CommonHeader title="Votre demande concerne ?" />
      <main class="main column flex1">
        <div class="column evenly flex1">
          <div @click="goNextStep(), customerData.isNew = true" class="btn--large primaryBgColor">
            <span class="icon icon-thumb-up"></span>
            <span class="btn--large--text">Neuf</span>
         </div>
          <div @click="goNextStep(), customerData.isNew = false" class="btn--large secondaryBgColor">
              <span class="icon icon-renovation"></span>
              <span class="btn--large--text">En rénovation</span>
          </div>
        </div>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor flex5"  @click="goPrevStep">
          Retour
        </button>
      </div>
    </section>


 <!-- ancienne currentStep 3 -->
    <section v-if="currentStep == 4" class="step step3 column flex1">
      <CommonHeader title="Votre demande concerne ?" />
      <main class="main column flex1">
        <div class="column evenly flex1">
          <div @click="goNextStep(), customerData.isHouse = true" class="btn--large primaryBgColor">
              <span class="icon icon-house"></span>
              <span class="btn--large--text">Maison</span>
          </div>
          <div @click="goNextStep(), customerData.isHouse = false" class="btn--large secondaryBgColor">
              <span class="icon icon-appartment"></span>
              <span class="btn--large--text">Appartement</span>
          </div>
        </div>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor flex5"  @click="goPrevStep()">
          Retour
        </button>
      </div>
    </section>





    <!-- ancienne currentStep 4 -->
    <section v-if="currentStep == 5" class="step step4 column flex1">
      <CommonHeader title="Sa configuration ?" />
      <main class="main column flex1">
        <div class="column evenly flex1">
          <div @click="goNextStep(), customerData.hasFloor = false" class="btn--large primaryBgColor">
              <span class="icon icon-floor"></span>
              <span class="btn--large--text">Plain-Pied</span>
          </div>
          <div @click="goNextStep(), customerData.hasFloor = true" class="btn--large secondaryBgColor">
              <span class="icon icon-floors"></span>
              <span class="btn--large--text">Étages</span>
          </div>
        </div>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor flex5"  @click="goPrevStep()">
          Retour
        </button>
      </div>
    </section>





    <!--section v-if="currentStep == 5" class="step step5 column flex1">
      <CommonHeader title="Votre demande concerne ?" />
      <main class="main column flex1">
        <div class="column evenly flex1">
          <div @click="goNextStep(), customerData.isNew = true" class="btn--large primaryBgColor">
            <span class="icon icon-thumb-up"></span>
            <span class="btn--large--text">Neuf</span>
         </div>
          <div @click="goNextStep(), customerData.isNew = false" class="btn--large secondaryBgColor">
              <span class="icon icon-renovation"></span>
              <span class="btn--large--text">En rénovation</span>
          </div>
        </div>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor flex5"  @click="goPrevStep">
          Retour
        </button>
      </div>
    </section-->




    <section v-if="currentStep == 6 && customerData.isNew == false" class="step step6 column flex1">
      <CommonHeader title="Vos murs sont ..." />
      <main class="main column flex1">
        <div v-if="!loading" class="column evenly flex1">
          <div @click="goNextStep(), customerData.isClean = true" class="btn--large primaryBgColor">
              <span class="icon icon-clean"></span>
              <span class="btn--large--text">Propres</span>
          </div>
          <div @click="estimationNotPossible()" class="btn--large secondaryBgColor">
              <span class="icon icon-trowel"></span>
              <span class="btn--large--text">À reprendre</span>
          </div>
        </div>
        <LoadingSpinner v-if="loading"/>
      </main>
      <p v-if="postErrorMsg != '' " class="text-center">{{postErrorMsg}}</p>
      <div class="pagination row center">
        <button :disabled="loading" class="btn primaryBgColor flex5"  @click="goPrevStep()">
          Retour
        </button>
      </div>
    </section>





    <section v-if="currentStep == 7 && customerData.notPossible == true" class="step step7 flex1 column between">
      <header class="header  column row_center center">
        <img class="logo--medium" src="../assets/logo.svg" alt="Logo de Blanc Pur Peinture, les artisans spécialistes de la peinture au spray">
        <h1 class="title title1">Voilà un projet intéressant !</h1>
        <div class="separation--short"></div>
      </header> 
      <main class="main flex5 column row_center between">
        <p class="text text-center">Nous serions ravis d’être à vos côtés pour mener à bien ces travaux.</p>
        <p class="text text-center">Afin d’ obtenir un devis précis, un technicien conseil va prendre contact avec vous et vous proposer un rendez vous sur place. Il fera un diagnostic plus précis des surfaces à reprendre et pourra vous suggérer les meilleures finitions à prévoir.</p>
        <p class="text text-center">Voici son nom et coordonnées :</p>
        <div class="card column row_center">
          <p class="text text-center">Technicien conseil</p>
          <p class="text text-center">Ulrich Ducharne</p>
          <a class="text text-center" href="tel:+33768892129">07 68 89 21 29</a>
        </div>
        <p class="text text-center">En attendant, nous restons à votre disposition pour toute demande complémentaire.</p>
        <h2 class="title4">Notez que blanc pur peinture c’est aussi :</h2>
        <p class="text text-center">Les <strong class="text--important">revêtements de sols</strong> & les <strong class="text--important">Conseils Couleur</strong> pour une <strong class="text-span">décoration unique, originale</strong> et <strong class="text-span">aboutie</strong>.</p>
        <button class="need-mTB btn primaryBgColor" @click="currentStep = 0">
          Accueil
        </button>
      </main>
      <!--div class="pagination row center">
        <button :disabled="loading" class="btn primaryBgColor"  @click="goPrevStep()">
          Retour
        </button>
        <button :disabled="loading" class="btn secondaryBgColor text-center" @click="saveDatatoSheet()">
          Envoyer<span>ma demande</span>
        </button>
      </div-->
      <!--div class="post-error-msg flex1 column row_center center"-->
         <contact-component contactText="Contact :" href1="tel:+33768892129" href2="mailto:bonjour@blancpurpeinture.fr" icon1="phone2" icon2="arroba" />
      <!--/div-->
    </section>



    <section v-if="currentStep == 6 && customerData.isNew == true" class="step step8 column flex1">
      <CommonHeader title="Vous souhaitez ..." />
      <main class="main column flex1">
        <div class="column evenly flex1">
          <div @click="goNextStep(), customerData.isSimple = true, this.customerData.finalSelectedRooms = []" class="btn--large primaryBgColor">
              <span class="icon icon-quick-estimate"></span>
              <span class="btn--large--text">Une estimation<br/>rapide</span>
          </div>
          <div @click="goNextStep(), customerData.isSimple = false" class="btn--large secondaryBgColor">
              <span class="icon icon-detailed-estimate"></span>
              <span class="btn--large--text">Une estimation détaillée</span>
          </div>
        </div>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor flex5"  @click="goPrevStep()">
          Retour
        </button>
      </div>
    </section>

    <section v-if="currentStep == 7 && customerData.isSimple == true && customerData.isNew == true" class="step step9 column flex1">
      <CommonHeader title="quelle est la superficie de votre logement ?"/>
      <main class="main column between flex1">
        <div class="flex1">
          <div class="form">
            <div v-if="!customerData.isExactTotalSurface">
              <p class="text--style-important">sol</p>
              <p class="text-info">Veuillez indiquer en <strong class="text-span">mètres carrés</strong> la <strong class="text-span">surface au sol</strong> de vos planchers.</p>
            </div>
            <div v-else>
              <p class="text--style-important">mur <span class="primaryColor">&</span> plafonds</p>
              <p class="text-info">Veuillez indiquer en <strong class="text-span">mètres carrés</strong> la <strong class="text-span">surface</strong> de vos <strong class="text-span">murs</strong> & <strong class="text-span">plafonds additionnée</strong>.</p>
            </div>
            <div class="form-item form-item--input-number relative">
              <input v-model="customerData.estimatedTotalSurface" class="input" type="number" name="totalfloorarea" id="totalfloorarea" oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" min="0" max="400" placeholder="90" required>
              <span class="input-number-span">M²</span>
            </div>
          </div>
          <div class="centered_absolute-element row row_center">
            <input type="checkbox" id="btnwallsurface" class="checkbox" @click="checkOnOff()" :checked="customerData.isExactTotalSurface">
            <label class="btn--onoff" for="btnwallsurface">Je connais la surface exacte des murs et plafonds.</label>
          </div>
        </div>
        <p class="error--msg">{{formErrorMsg}}</p>
      </main>
      <LoadingSpinner v-if="loading"/>
      <div class="pagination row center">
        <button :disabled="loading" class="btn primaryBgColor" @click="resetSurfacesData()">
          Retour
        </button>
        <button :disabled="loading" class="btn secondaryBgColor" @click="checkSurface()">
          Continuer
        </button>
      </div>
    </section>


    <section v-if="(currentStep == 7 && customerData.isSimple == false && customerData.isNew == true && detailRooms == false) || (currentStep == 7 && customerData.isClean == true && customerData.isNew == false && detailRooms == false)" class="step step10 column flex1">
      <CommonHeader title="Faisons le tour du propriétaire en détail"/>
      <main class="main flex1">
        <div class="form flex1 row wrap">
          <div class="roomsChoice" v-for="room in rooms" :key="room.name">
            <input class="checkbox" type="checkbox" :id="room.name" :name="room.name" :checked="room.chosen">
            <label :for="room.name" @click.prevent="room.chosen = !room.chosen" class="roomsChoice--label column row_center center" ><span :class="['icon', room.icon ]"></span>{{room.name}}</label>
          </div> 
        </div>
        <!--p v-for="room in rooms" :key="room.name">{{room.name}} : choisi ? {{room.chosen}}</p-->
      </main>
      <p class="error--msg">{{formErrorMsg}}</p>
      <div class="pagination row center">
        <button class="btn primaryBgColor"  @click="goPrevStep(), selectedRooms = []">
          Retour
        </button>
        <button class="btn secondaryBgColor" @click="pushSelectedRooms()">
            Continuer
        </button>
      </div>
    </section>



    <!-- POP UP -->
    <section v-if="detailRooms" class="step step11 popup column flex1 between">
      <CommonHeader title="Indiquez la surface au sol de la pièce"/>
      <main class="main flex1 column between">
        <!--p>Pièces finales : {{ customerData.finalSelectedRooms }}</p-->
        <div class="flex1 if-empty" v-for="(selectedRoom, index) in selectedRooms" :key="selectedRoom.name">
          <div v-if="index == currentIndexSelectedRoom" class="form flex1" :class="'main-room-' + index">
            <div class="form-item form-item--input-number2 relative">
              <div class="primaryBgColor row row_center between">
                <div class="flex1 row row_center">
                  <span @click="deleteRoom(index)" class="icon"></span>
                  <p class="text">{{selectedRoom.name}}</p>
                </div>
                <div class="flex5 row row_center">
                  <input v-model="selectedRoom.newExactSurface" class="input" type="number" :name="selectedRoom.name" oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" min="0" max="400" :placeholder="selectedRoom.defaultSurface" required>
                  <span class="input-number-span">M²</span>
                </div>
              </div>
            </div>
            <div v-if="customerData.finalSelectedRooms[index].definedRooms.length">
              <div class="form-item form-item--input-number2 relative" v-for="(finalDefinedRoom, index2) in customerData.finalSelectedRooms[index].definedRooms" :key="finalDefinedRoom.name">
                <div class="primaryBgColor row row_center between">
                  <div class="flex1 row row_center">
                    <span @click="deleteDefinedRoom(index2)" class="icon icon-cross"></span>
                    <p class="text">{{finalDefinedRoom.name}}</p>
                  </div>
                  <div class="flex5 row row_center">
                    <input v-model="finalDefinedRoom.newExactSurface" class="input" type="number" name="finalDefinedRoom.name" min="0" oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" max="400" :placeholder="finalDefinedRoom.defaultSurface" required>
                    <span class="input-number-span">M²</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="add-same-room">
              <p @click="duplicateThisRoom()" class="btn--plus secondaryColor row row_center"><span class="icon icon-plus"></span> Ajouter une pièce " {{ selectedRoom.name }} "</p>
            </div>
          </div>
        </div>
        <div class="separation--large"></div>
        <p class="text text-center">Veuillez indiquer en <strong>mètres carrés</strong> la <strong>surface au sol</strong> dans chacune des pièces que vous ajoutez.</p>
      </main>
      <LoadingSpinner v-if="loading"/>
      <div class="pagination row center">
        <div :disabled="loading" class="btn primaryBgColor"  @click="showPrevSelectedRoom()">
            Retour
        </div>
        <div :disabled="loading" class="btn secondaryBgColor"  @click="showNextSelectedRoom()">
            Continuer
        </div>
      </div>
    </section>


    <section v-if="currentStep == 8" class="step step12 column flex1">
      <CommonHeader title="Quelle finition souhaitez-vous ?"/>
      <main class="main flex1 column between">
        <input class="input-radio" type="radio" name="finition" id="finition1" v-model="customerData.finition" value="11">
        <label class="radio-label btn--large" for="finition1" @click="priceCalcul(10)">
            <!--span class="icon icon-house"></span-->
           
            Chantier soigné
        </label>
        <div class="text text-center need-mB2">
          <p>PEINTURE ACRYLIQUE BLANC - MATE D'UNE GRANDE BLANCHEUR & LAVABLE</p>
          <strong> IDEAL : PREMIER BUDGET</strong>
          <p>COV &#x3C; 1GR/L</p>
          <div class="logos-finition row row_center center">
             <img class="need-mR" src="../assets/sofec.svg" alt="Peinture Sofec">
             <img class="" src="../assets/emissions-air.png" alt="Emission classe A">
          </div>
        </div>
        <input class="input-radio" type="radio" name="finition" id="finition2" v-model="customerData.finition" value="12">
        <label class="radio-label btn--large" for="finition2" @click="priceCalcul(11)">
            <!--span class="icon icon-house"></span-->
            
            Summum
        </label>
        <div class="text text-center need-mB2">
          <p>PEINTURE ACRYLIQUE GRANDE MATITÉ - SANS REFLET NI BRILLANCE</p>
          <strong>IDEAL : MAISON LUMINEUSE ET GRAND ESPACE</strong>
          <p>COV 2GR/L</p>
          <div class="logos-finition row row_center center">
             <img class="need-mR" src="../assets/seigneurie.svg" alt="Peinture Seigneurie">
             <img class="need-mR" src="../assets/nf-environnement.png" alt="NF Environnement">
             <img class="" src="../assets/emissions-air.png" alt="Emission classe A">
          </div>
        </div>
        <input class="input-radio" type="radio" name="finition" id="finition3" v-model="customerData.finition" value="13">
        <label class="radio-label btn--large" for="finition3" @click="priceCalcul(12)">
            <!--span class="icon icon-house"></span-->
            
            Resis’Temps
        </label>
        <div class="text text-center need-mB2">
          <p>PEINTURE ACRYLIQUE MATE - HAUTE PERFORMANCE MAT PROFOND - GRAIN FIN & LESSIVABLE</p>
          <strong>IDEAL : COMMERCE, FAMILLE OU LOGEMENT EN LOCATIF</strong>
          <p>COV  &#x3C; 10GR/L </p>
          <div class="logos-finition row row_center center">
             <img class="need-mR" src="../assets/peinture-gauthier.png" alt="Peinture Gauthier">
             <img class="need-mR" src="../assets/nf-environnement.png" alt="NF Environnement">
             <img class="" src="../assets/emissions-air.png" alt="Emission classe A">
          </div>
        </div>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor"  @click="goPrevStep()">
          Retour
        </button>
      </div>
    </section>

    <!-- POP UP -->
    <section v-if="detailPrice" class="step step13 popup column between flex1">
      <CommonHeader title=""/>
      <h1 class="title title1">Peintre est un <span class="secondaryColor">véritable</span> métier</h1>
      <main class="main flex1 column row_center evenly">
          <span class="separation--short secondaryBgColor"></span> 
          <h2 class="title3">Pour votre chantier voici ce que nous avons prévu :</h2>
          <p class="text text-center">Préparation du chantier</p>
          <span class="separation--rounded"></span> 
          <p class="text text-center">Ponçage mécanique des surfaces</p>
          <p class="text text-center">(murs et plafonds)</p>
          <span class="separation--rounded"></span> 
          <p class="text text-center">Masquage des surfaces non peintes (portes et cadres, menuiseries, sols, prises éléctriques…)</p>
          <span class="separation--rounded"></span> 
          <p class="text text-center">Couche d'impression</p>
          <span class="separation--rounded"></span>
          <p class="text text-center">Révision des enduits</p>
          <p class="text text-center">( défauts, trous, rayures ... )</p>
          <span class="separation--rounded"></span> 
          <p class="text text-center">À nouveau un ponçage manuel des surfaces</p>
          <span class="separation--rounded"></span> 
          <p class="text text-center">Couches de finition</p>
          <span class="separation--rounded"></span> 
          <p class="text text-center">Nettoyage du chantier</p>
          <div class="logos row row-center between">
            <img src="../assets/sofec.svg" />
            <img src="../assets/seigneurie.svg" />
            <img src="../assets/blime.svg" />
          </div>
      </main>
      <div class="pagination row center">
        <div class="btn primaryBgColor flex5"  @click="detailPrice = !detailPrice">
            Retour
        </div>
      </div>

    </section>


    <section v-if="currentStep == 9" class="step step14 column flex1">
      <CommonHeader title="Voici votre estimation"/>
      <main class="main column flex1">
          <div class="column flex1 between">
              <div class="column row_center">
                  <p class="old-price" v-if="customerData.newPrice != '' ">{{ customerData.price }}€</p>
                  <div class="price" v-if="customerData.newPrice != ''">{{ customerData.newPrice }}€</div>
                  <div class="price" v-if="customerData.newPrice == ''">{{ customerData.price }}€</div>

                  <p class="popup-link secondaryColor text-center" @click="detailPrice = !detailPrice">
                      - Voir ce que comprend le prix -
                  </p>
                  <div class="promo row between row_center need-mT" :class="{'second-style' : isExactPromo}">
                    <span v-if="isExactPromo" @click="resetPromo()" class="icon icon-cross"></span>
                    <input class="input-promo" :disabled="isExactPromo" type="text" v-model="customerData.promo" placeholder="Code Promo" />
                    <input type="submit" @click.prevent="checkPromo()" class="input-promo--submit btn primaryBgColor flex1" :value="[isExactPromo ? 'Validé' : 'Ajouter' ]" :disabled="isExactPromo" />
                  </div>
                  <p class="text-info text-error" v-if="promoErrorMsg != '' "><strong class="text--important">{{ promoErrorMsg }}</strong></p>
                  <!--p class="text-info" v-if="isExactPromo == false">Ajoutez un code promo afin d’obtenir une réduction sur le prix de votre estimation.</p-->
                  <p class="text-info text-promo"  v-if="isExactPromo == true">Vous avez opté pour la promotion :<br/> <strong class="text--important">{{ customerData.promoChoice }}</strong></p>
                  <div class="promo--choices step column center flex1" v-if="visiblePromoChoice">
                    <p class="text-info"><strong>Choisissez</strong> parmi <strong class="text--important">l'une</strong> des <strong>deux promotions :</strong></p>
                    <div class="btn-container column evenly">
                      <input type="radio" id="promoChoice1"
                      name="promoChoices" value="réduction du prix" v-model="customerData.promoChoice" @change="newPriceCalcul()">
                      <label class="btn--large primaryBgColor" for="promoChoice1">réduction du prix</label>

                      <input type="radio" id="promoChoice2"
                      name="promoChoices" value="peinture offerte" v-model="customerData.promoChoice" @change="newPriceCalcul()">
                      <label class="btn--large secondaryBgColor" for="promoChoice2" >peinture tête de lit couleur offerte</label>
                    </div>
                  </div>
              </div>
              <div class="need-mT2">
                  <!--p class="text-info">
                    Nous ne souhaitons pas vous perdre en vous proposant un large choix de finitions sans pouvoir vous les détailler.<br/>
                    Cette estimation est donc basée sur l'application d'une peinture blanche, mat, lavable et française ... <br/>
                    Un souhait différent ? Contactez-nous ...
                  </p-->
                  <div class="separation--large"></div>
                  <p class="text-info">Cette estimation doit être validée par notre technicien conseil pour être transformée en devis.</p>
                  <div class="need-mTB centered_absolute-element row row_center"> 
                      <input type="checkbox" id="recall2" class="checkbox" @click="beCalledBack()" :checked="customerData.recall">
                      <label class="btn--onoff" for="recall2">Se faire rappeler par un technicien dans les 24h.</label>
                  </div>
              </div>
          </div>
      </main>
      <div class="pagination row center">
        <button class="btn primaryBgColor"  @click="resetPrice()">
          Retour
        </button>
        <button class="btn secondaryBgColor" @click="goNextStep()">
          Continuer
        </button>
      </div>
    </section>




    <section v-if="currentStep == 10" class="step step15 column flex1" id="step14">
      <CommonHeader title="Avez-vous une question ou une remarque ?" />
      <main v-if="!loading" class="main flex1">
        <div class="form flex1">
          <textarea id="comments" name="comments" class="textarea" v-model="customerData.comments" placeholder="Écrire ici ...">
          </textarea>
        </div>
        <p class="text-info">Cette étape est facultative.</p>
      </main>
      <LoadingSpinner v-if="loading"/>
      <div class="pagination row center">
        <button :disabled="loading" class="btn primaryBgColor"  @click="goPrevStep()">
          Retour
        </button>
        <button :disabled="loading" class="btn secondaryBgColor" @click="sendMail()">
          Recevoir<span>l'estimation par mail</span>
        </button>
      </div>
      <div v-if="postErrorMsg != '' " class="post-error-msg flex1 column row_center center">
        <img class="logo--medium" src="../assets/logo.svg" alt="Logo de Blanc Pur Peinture, les artisans spécialistes de la peinture au spray">
        <p class="text-center">{{postErrorMsg}}</p>
        <button class="need-mTB btn primaryBgColor" @click="currentStep = 0">
          Accueil
        </button>
         <Contact contactText="Contact :" href1="tel:+33768892129" href2="mailto:bonjour@blancpurpeinture.fr" icon1="phone2" icon2="arroba" />
      </div>
    </section>



    <section v-if="currentStep == 11" class="step step16 column flex1" id="step15">
      <CommonHeader title=""/>
      <h1 class="title title1">Blanc Pur Peinture vous <span class="secondaryColor">remercie</span> .</h1>
      <main class="main flex1 column row_center center">
        <span class="separation--short secondaryBgColor"></span>
        <p class="text text-center">Un <strong class="text-span">mail recapitulatif</strong> et <strong class="text-span">détaillé</strong> de votre estimation <strong class="text-span">va vous être envoyé rapidement</strong>, et si vous l’avez demandé, un <strong class="text-span">technicien</strong> prendra <strong class="text-span">contact</strong> avec vous dans les <strong class="text-span">prochaines 24h …</strong></p>
        <p class="text text-center">Voici son nom et coordonnées :</p>
        <div class="card column row_center">
          <p class="text text-center">Technicien conseil</p>
          <p class="text text-center">Ulrich Ducharne</p>
          <a class="text text-center" href="tel:+33768892129">07 68 89 21 29</a>
        </div>
        <p class="text text-center">En attendant, nous restons à votre disposition pour toute demande complémentaire.</p>
        <h2 class="title4">Notez que blanc pur peinture c’est aussi :</h2>
        <p class="text text-center">Les <strong class="text--important">revêtements de sols</strong> & les <strong class="text--important">Conseils Couleur</strong> pour une <strong class="text-span">décoration unique, originale</strong> et <strong class="text-span">aboutie</strong>.</p>
      </main>
      <div class="pagination row center">
        <button class="btn secondaryBgColor" @click="currentStep = 0">
          Accueil
        </button>
      </div>
    </section>

    

    <footer class="footer" v-if="!detailPrice">
      <div v-if="currentStep != 0 && currentStep != 10 " class="progressbar--container row row_center center">
        <progress class="progressbar" max="100" :value="this.ratioProgressBar">{{ this.ratioProgressBar }}% </progress>
      </div> 
      <Contact v-if="currentStep == 0" contactText="Contact :" href1="tel:+33768892129" href2="mailto:bonjour@blancpurpeinture.fr" icon1="phone2" icon2="arroba" />
      <Contact v-if="currentStep == 11" contactText="Contact :" href1="https://www.facebook.com/Blanc-Pur-Peinture-102357285587519" href2="https://instagram.com/blancpurpeinture?utm_medium=copy_link" icon1="facebook" icon2="instagram" />
    </footer>
   
  </div>
</template>

<script>
// @ is an alias to /src
import CommonHeader from '@/components/CommonHeader.vue'
//import Pagination from '@/components/Pagination.vue'
import ContactComponent from '@/components/ContactComponent.vue'
//import ProgressBar from '@/components/ProgressBar.vue'
import RgpdComponent from '@/components/RgpdComponent.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'



export default {
  name: 'HomeView',
  components: {
    CommonHeader,
    //Pagination,
    ContactComponent,
    RgpdComponent,
    LoadingSpinner
    //ProgressBar
  },
  data() {
    return{

      customerData : {
          name: "",
          cellphone: "",
          email: "",
          address: "",
          date: "",
          isHouse: false,
          hasFloor: false,
          isNew: false,
          isSimple: false,
          isExactTotalSurface: false, //true == Surface murs et plafonds
          estimatedTotalSurface: 0, // Estimation rapide : nbr de m2
          isClean: false,
          notPossible : false, //Si l'estimation n'est pas spossible (reno + murs à reprendre) == true
          finalSelectedRooms: [],
          price: '',
          newPrice: '',
          recall : false,
          promo:"",
          promoChoice: "",
          comments: "",
          finition: ""
      },

      loading : false,
      
      prevStep: 0,
      currentStep: 0, 

      promoErrorMsg:"",
      promos: ["constlepontet", "constavignon", "constmonteux", "constcarpentras", "constorange", "constlesangles", "constgard", "constvaucluse", "constbdr"],
      

      isExactPromo: false,
      visiblePromoChoice : false,

      rgpdAccepted: false,
      visibleRgpd : false,

      //Validation des champs des formulaires
      formErrorMsg: "",
      dateChosen : false,

      detailPrice : false,

      detailRooms : false,
      currentIndexSelectedRoom: 0,
      // Somme des surfaces de chaque pièce sélectionnée
      totalRoomsSurfaces : 0,

      //Step2 lieu du chantier
      defaultValue: 'Choisissez un délai',
			list: ["Urgent, moins de 3 mois","De 3 à 6 mois","De 6 mois à 1 an"],
      visibleOptions: false,

      postEntryId : "",
      postErrorMsg : "",


      //step10 et 11 estimation détaillée et suite
      rooms: [
        { name: "Chambre", icon: "icon-double-bed", defaultSurface: 9, newExactSurface: Number, chosen: false, definedRooms: [] },
        { name: "Séjour", icon: "icon-living-room", defaultSurface: 20, newExactSurface: Number, chosen: false, definedRooms: [] },
        { name: "Cuisine", icon: "icon-kitchen", defaultSurface: 6, newExactSurface: Number, chosen: false, definedRooms: [] },
        { name: "Salle de bain", icon: "icon-bathroom", defaultSurface: 4, newExactSurface: Number, chosen: false, definedRooms: [] },
        { name: "W.C", icon: "icon-wc", defaultSurface: 1, newExactSurface: Number, chosen: false, definedRooms: [] },
        { name: "Couloir", icon: "icon-room", defaultSurface: 4, newExactSurface: Number, chosen: false, definedRooms: [] },
        { name: "Bureau", icon: "icon-workplace", defaultSurface: 9, newExactSurface: Number, chosen: false, definedRooms: [] },
        { name: "Autre", icon: "icon-house-plan", defaultSurface: 9, newExactSurface: Number, chosen: false, definedRooms: []},
      ],

      selectedRooms: [],


    }
  },
  computed: {
   
    ratioProgressBar(){
      var x = (parseInt(this.currentStep) / 10) * 100
      return x
    },

   


    // Vérif des champs des formulaires
    isValidEmail(){
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.customerData.email);
    },
    isValidCellphone(){
      var re = /^[0-9]{10}$/
      return re.test(this.customerData.cellphone);
    }

  },
  


  methods: {
    /*******************
     *    Pagination   *
     ******************/

    goPrevStep(){
      this.prevStep = this.currentStep
      this.currentStep -= 1
    },
    goNextStep(){
      this.prevStep = this.currentStep
      this.currentStep += 1
      this.formErrorMsg = ""
    },

    showPrevSelectedRoom(){
      if (this.currentIndexSelectedRoom == 0 ){
        this.detailRooms = false
        //this.currentStep = 8
      } else{
        this.selectedRooms.index = this.currentIndexSelectedRoom
        this.currentIndexSelectedRoom -= 1
      }
    },

    showNextSelectedRoom(){
      //S'il n'y a plus de pièces :
      if (this.currentIndexSelectedRoom == (this.selectedRooms.length - 1 )){
        this.detailRooms = false
        this.currentIndexSelectedRoom = 0
        this.roomsSurfacesSum()
        this.currentStep = 8
      } else{ //S'il y a encore des pièces :
        this.selectedRooms.index = this.currentIndexSelectedRoom
        this.currentIndexSelectedRoom += 1
      }
    },


    /*********************
     * Form Valildations *
     *********************/

    
    showRgpd(){
      this.visibleRgpd = !this.visibleRgpd
    },
    checkRgpd(){
      if (!this.rgpdAccepted){
        this.formErrorMsg = "Merci de bien vouloir accepter les conditions d'utilisation"
      } else{
        this.customerData.hasFloor = false
        this.customerData.isClean = false
        this.customerData.isSimple = false
        this.customerData.notPossible = false
        this.customerData.isExactTotalSurface = false
        this.customerData.estimatedTotalSurface = 0
        this.customerData.finalSelectedRooms = []
        this.customerData.price = 0
        this.customerData.newPrice = 0
        this.customerData.promo = ''
        this.customerData.promoChoice = ''
        this.isExactPromo = false
        this.postErrorMsg = ""
        this.goNextStep()

      }
    },
    checkIdentityForm() {
      if((this.customerData.cellphone == "") || (this.customerData.email == "") || (this.customerData.name == "")){
        this.formErrorMsg = 'Tous les champs sont obligatoires';
      }else if(!this.isValidCellphone){
        this.formErrorMsg = 'Numéro de téléphone invalide...';
      }else if (!this.isValidEmail) {
        this.formErrorMsg = 'Mail invalide... exemple: polnareff@gmail.com';
      } else {
        this.formErrorMsg = "";
        this.goNextStep()
      }
    },
    checkSiteForm(){
      if((this.customerData.address == "") || (this.dateChosen == false)){
        this.formErrorMsg = 'Tous les champs sont obligatoires';
      }
      else {
        this.goNextStep()
      }
    },

    estimationNotPossible(){
      this.customerData.isClean = false, 
      this.customerData.notPossible = true,
      this.saveDatatoSheet()
    },

    /******************
     *  Form Options  *
     ******************/
    //Step2 lieu du chantier
    toggle() {
      this.visibleOptions = !this.visibleOptions;
    },
    select(option) {
        this.customerData.date = option;
        this.dateChosen = true
    },

    //Step9 estimation rapide
    checkOnOff() {
      this.customerData.isExactTotalSurface = !this.customerData.isExactTotalSurface
    },

    //Souhait d'être rappelé
    beCalledBack(){
      this.customerData.recall = !this.customerData.recall
    }, 

    //Si retour en arrière, on efface les données renseignées
    resetSurfacesData(){
      this.customerData.estimatedTotalSurface = 0 
      this.customerData.isExactTotalSurface = false
      this.goPrevStep()
    },
    resetPrice(){
      this.customerData.price = 0
      this.customerData.newPrice = 0
      this.customerData.promo = ''
      this.customerData.promoChoice = ''
      this.isExactPromo = false
      this.goPrevStep()
    },

    
    // Step13 vérification code promo
    checkPromo(){
      if(this.promos.indexOf(this.customerData.promo.toLowerCase()) !== -1){
        this.isExactPromo = true
        this.promoErrorMsg = ""
        this.visiblePromoChoice = true
      } else {
        this.promoErrorMsg = "Ce code promo n'est pas valide.. Pensez à vérifier l'orthographe."
      }
    },

    //Checke si surface renseignée est ok
    checkSurface(){
      if (parseInt(this.customerData.estimatedTotalSurface) > 0){
        this.goNextStep()
      } else{
        this.formErrorMsg = "Veuillez renseigner le nombre de m2"
      }
    },



    /**************************
     *  RoomSpecific Actions  *
     **************************/
 // Prix si estimation détaillée
 roomsSurfacesSum() {
      this.customerData.finalSelectedRooms.forEach(roomSurface => {
        if (roomSurface.newExactSurface >= 0){
          this.totalRoomsSurfaces += roomSurface.newExactSurface
        } else{
          this.totalRoomsSurfaces += roomSurface.defaultSurface
        }
        if (roomSurface.definedRooms.length > 0){
          roomSurface.definedRooms.forEach(definedroomSurface => {
            if ( isNaN(definedroomSurface.newExactSurface)){
              this.totalRoomsSurfaces += definedroomSurface.defaultSurface
            } else{
              this.totalRoomsSurfaces += definedroomSurface.newExactSurface
            }
          })
        }
      });
    },

   

    // Estimation détaillée : pièces sélectionnées
    pushSelectedRooms(){
      this.selectedRooms = this.rooms.filter( ( room ) => { 
             return room.chosen === true;
        });
      this.customerData.finalSelectedRooms = this.selectedRooms
      if (this.customerData.finalSelectedRooms.length > 0){
        this.detailRooms = !this.detailRooms
        // Je réinitialise la somme des surfaces des pièces sélectionnées (sinon tout se ré-additionne)
        this.totalRoomsSurfaces = 0
      } else {
        this.formErrorMsg = "Merci de sélectionner au moins une pièce"
      }
      
    },

    duplicateThisRoom(){
      // AU click, je crée un nouvel item identique à celui affiché sur la page et je le place dans le tableau definedRooms contenu dans ce même objet affiché
      this.customerData.finalSelectedRooms[this.currentIndexSelectedRoom].definedRooms.push({ 
        name: this.selectedRooms[this.currentIndexSelectedRoom].name, 
        //icon:"",
        defaultSurface: this.selectedRooms[this.currentIndexSelectedRoom].defaultSurface, 
        newExactSurface: this.selectedRooms[this.currentIndexSelectedRoom].newExactSurface, 
        //chosen: this.selectedRooms[this.currentIndexSelectedRoom].chosen, 
        //definedRooms: []
      });       
    },

    /*deleteRoom(index){ // On ne peut supprimer la 1ere pièce, il faut la décocher à l'étape précédente
      this.customerData.finalSelectedRooms.splice(index, 1)
    },*/
    deleteDefinedRoom(index){
      this.customerData.finalSelectedRooms[this.currentIndexSelectedRoom].definedRooms.splice(index, 1)
    },

    /******************
     *  Price Compute *
     ******************/

    //EDIT 22/06 -> methode pour les 2 chemins : estimation rapide & estimation détaillée
    priceCalcul(finitionPrice){
      //Si parcours estimation simple :
      if(this.customerData.isSimple){
        this.customerData.price = parseInt(this.customerData.estimatedTotalSurface * finitionPrice)
        if( !this.customerData.isExactTotalSurface){
          this.customerData.price = parseInt(this.customerData.price * 3.5)
        }
      } else{
        this.customerData.price = parseInt(this.totalRoomsSurfaces * finitionPrice * 3.5)
      }
      if (this.customerData.hasFloor == true){
          this.customerData.price += 300
      }
      //Ajout TVA 20% :
      this.customerData.price += (this.customerData.price * 0.2)
      console.log(this.customerData.price)

      //Arrondir à 1 chiffre après la virgule
      this.customerData.price = Math.round(((this.customerData.price)  * 10) / 10)

      if (finitionPrice == "11"){
        this.customerData.finition = "Soigné"
      } else if (finitionPrice == "12") {
        this.customerData.finition = "Sommum"
      } else {
        this.customerData.finition = "Resis'temps"
      }
      this.saveDatatoSheet()
      this.goNextStep()
    },



    //Recalcul du prix avec promo
    newPriceCalcul(){
      this.visiblePromoChoice = false
      if(this.customerData.promoChoice == "réduction du prix"){
        /*var diff = parseInt(this.customerData.price) / 12
        this.customerData.newPrice = Math.floor(diff * 11)
        this.customerData.newPrice = Math.round(this.customerData.newPrice) */

        //Réduc de 5% :
        var percentage = parseInt(this.customerData.price) * 0.05
        this.customerData.newPrice = Math.round(this.customerData.price - percentage)
        
      }
    },

     // Si le code promo entré est supprimé en cliquant sur l'icon cross
    resetPromo(){
      this.isExactPromo = false
      this.customerData.promo = ''
      this.customerData.promoChoice = ''
      this.customerData.newPrice = ''
    },

   


    /******************************
     * Envoi des données à l'api  *
     ******************************/
   


    //
    async saveDatatoSheet(){
      
      var result = { 
        nom : this.customerData.name,
        telephone: this.customerData.cellphone,
        email: this.customerData.email,
        address: this.customerData.address,
        date: this.customerData.date,
        isHouse: this.customerData.isHouse,
        hasFloor: this.customerData.hasFloor,
        isNew: this.customerData.isNew,
        isSimple: this.customerData.isSimple,
        isExactTotalSurface: this.customerData.isExactTotalSurface,
        estimatedTotalSurface: this.customerData.estimatedTotalSurface, 
        isClean: this.customerData.isClean,
        finitionName : this.customerData.finition, //NOUVELLE DATA
        price: this.customerData.price,
        newPrice: this.customerData.newPrice,
        promo : this.customerData.promo,
        promoChoice: this.customerData.promoChoice,
        comments: this.customerData.comments,
        recall : this.customerData.recall,
        notPossible : this.customerData.notPossible,
        rooms : [] 
      }


      if (this.customerData.finalSelectedRooms.length > 0){
          this.customerData.finalSelectedRooms.forEach( room => {
            if(room.definedRooms.length > 0){
              room.definedRooms.forEach( sameRoom => {
                if (sameRoom.newExactSurface > 0){
                  result.rooms.push({
                    roomName : sameRoom.name,
                    roomSurface : sameRoom.newExactSurface
                  })
                } else{
                  result.rooms.push({
                    roomName : sameRoom.name,
                    roomSurface : sameRoom.defaultSurface
                  })
                }//else
              })//foreach
              
            }//if
            if (room.newExactSurface > 0){
              result.rooms.push({
                roomName : room.name,
                roomSurface : room.newExactSurface
              })
            } else{
              result.rooms.push({
                roomName : room.name,
                roomSurface : room.defaultSurface
              })
            }//else
            
          })//foreach   
      }//if

      let header = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(result)
        };

      this.loading = true;

      const response = await fetch(window.VUE_APP_API_URL+'/Gdoc', header);      
      if (response.status == '200'){
        this.loading = false;
        var ret = await response.text();
        console.log(ret);
        this.postEntryId = ret.replace(/["]/g, '');
        console.log(this.postEntryId);
        if (this.customerData.notPossible == true){
          this.currentStep = 7;
        } else {
          this.currentStep = 9;
        }
      } else {
        this.loading = false;
        this.postErrorMsg = "Nous sommes désolés, nous avons un problème technique. Vous pouvez recommencer l'estimation d'ici quelques minutes en revenant à l'accueil ou en nous contactant directement par mail ou par téléphone."
      } 
      
    },




    async sendMail(){

      var result = { 
        nom : this.customerData.name,
        telephone: this.customerData.cellphone,
        email: this.customerData.email,
        address: this.customerData.address,
        date: this.customerData.date,
        isHouse: this.customerData.isHouse,
        hasFloor: this.customerData.hasFloor,
        isNew: this.customerData.isNew,
        isSimple: this.customerData.isSimple,
        isExactTotalSurface: this.customerData.isExactTotalSurface,
        estimatedTotalSurface: this.customerData.estimatedTotalSurface, 
        isClean: this.customerData.isClean,
        finitionName : this.customerData.finition, // NOUVELLE DATA
        price: this.customerData.price,
        newPrice: this.customerData.newPrice,
        promo : this.customerData.promo,
        promoChoice: this.customerData.promoChoice,
        comments: this.customerData.comments,
        recall : this.customerData.recall,
        notPossible : this.customerData.notPossible,
        rooms : [] 
      }


      if (this.customerData.finalSelectedRooms.length > 0){
          this.customerData.finalSelectedRooms.forEach( room => {
            if(room.definedRooms.length > 0){
              room.definedRooms.forEach( sameRoom => {
                if (sameRoom.newExactSurface > 0){
                  result.rooms.push({
                    roomName : sameRoom.name,
                    roomSurface : sameRoom.newExactSurface
                  })
                } else{
                  result.rooms.push({
                    roomName : sameRoom.name,
                    roomSurface : sameRoom.defaultSurface
                  })
                }//else
              })//foreach
              
            }//if
            if (room.newExactSurface > 0){
              result.rooms.push({
                roomName : room.name,
                roomSurface : room.newExactSurface
              })
            } else{
              result.rooms.push({
                roomName : room.name,
                roomSurface : room.defaultSurface
              })
            }//else
            
          })//foreach   
      }//if

      if (this.customerData.finition == "10"){
        result.finitionName = "Soigné"
      } else if (this.customerData.finition == "11") {
        result.finitionName = "Sommum"
      } else {
        result.finitionName = "Resis'temps"
      }

      let header = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json; charset=utf-8'
            },
            method: "POST",
            body: JSON.stringify(result)
        };

      this.loading = true;

      const response = await fetch(window.VUE_APP_API_URL+'/Gdoc/'+this.postEntryId+"/mail", header);      
      if (response.status == '200'){
        this.loading = false;
        this.goNextStep();
      } else {
        this.loading = false;
        this.postErrorMsg = "Nous sommes désolés, nous avons un problème technique. Le mail n'a pas pu etre envoyé mais votre demande reste enregistrée."
      }     
      
    }
  }
}
</script>
